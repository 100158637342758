// languageLevels.js
export const LanguageLevels = {
  A1: "A1",
  A2: "A2",
  B1: "B1",
  B2: "B2",
  C1: "C1",
  C2: "C2",
};

export const LanguageDescriptions = {
  [LanguageLevels.A1]: "Beginner",
  [LanguageLevels.A2]: "Elementary",
  [LanguageLevels.B1]: "Intermediate",
  [LanguageLevels.B2]: "Upper Intermediate",
  [LanguageLevels.C1]: "Advanced",
  [LanguageLevels.C2]: "Proficiency",
};

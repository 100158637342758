<template>
  <div v-if="show" class="hint">
    {{ message }}
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "...",
    },
    duration: {
      type: Number,
      default: 3000, // 3 seconds
    },
  },
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.show = false;
    }, this.duration);
  },
};
</script>

<style scoped>
.hint {
  position: absolute;
  top: -30px; /* Adjust position as needed */
  left: 0;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
</style>

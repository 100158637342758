<template>
  <div class="threejs-container">
    <transition name="fade">
      <div v-if="!isLoaded" class="loading-overlay">
        <LoaderSpinner></LoaderSpinner>
      </div>
    </transition>
    <transition name="fade">
      <!-- The actual Three.js container -->
      <div ref="threejsContainer" class="threejs"></div>
    </transition>
    <div class="avatar-info">{{ teacher.name }}</div>

    <AvatarButtons
      v-if="variant == 'in-lesson'"
      class="avatar-buttons"
      @resume-audio="resumeAudio"
      @stop-audio="stopAudio"
    />
    <div v-on:click="gesturesAdd" class="test-gestures">GESTURES</div>
  </div>
</template>

<script>
import { markRaw } from "vue";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import AvatarButtons from "./AvatarButtons";

import * as HOST from "@amazon-sumerian-hosts/three";
import LoaderSpinner from "./LoaderSpinner.vue";

const renderFn = [];
let host = undefined;

export default {
  name: "AvatarView",
  emits: ["speaking-stopped"],
  props: {
    // Define your props here
    teacher: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
  },
  components: {
    AvatarButtons,
    LoaderSpinner,
  },

  data() {
    return {
      // Declare your Three.js objects here
      scene: null,
      clock: null,
      camera: null,
      renderer: null,
      controls: null,
      renderID: null,
      loadingStep: 0,
      maxLoadingSteps: 17,
    };
  },

  computed: {
    isLoaded() {
      return this.loadingStep === this.maxLoadingSteps;
    },
  },

  mounted() {
    // this.duckPunchAWS();

    this.initScene();
    this.increaseLoadingStep();
    this.initRenderer();
    this.increaseLoadingStep();
    this.initCamera();
    this.increaseLoadingStep();
    this.initControls();
    this.increaseLoadingStep();
    this.initLights(); // Initialize lights
    this.increaseLoadingStep();
    this.initEnvironment("/assets/images/Background3.jpg"); // Initialize environment
    this.increaseLoadingStep();
    this.initEnvironmentMap();
    this.increaseLoadingStep();
    let slug = this.teacher ? this.teacher.slug : "/adult_female/grace";
    const parts = slug.split("/");
    let avatarCategory;
    let avatarName;
    if (parts.length === 2) {
      avatarCategory = parts[0];
      avatarName = parts[1];
    }

    const characterParams = {
      characterFile: `/assets/glTF/characters/${avatarCategory}/${avatarName}/${avatarName}.gltf`,
      animationPath: `/assets/glTF/animations/${avatarCategory}`,
      audioAttachJoint: "chardef_c_neckB", // Name of the joint to attach audio to
      lookJoint: "charjx_c_look", // Name of the joint to use for point of interest target tracking
      voice: this.teacher.voice, // Polly voice. Full list of available voices at: https://docs.aws.amazon.com/polly/latest/dg/voicelist.html
    };

    this.loadCharacterAndAnimations(characterParams).then(() => {
      // JUST SAMPLES - TO FIX

      const shirt = `/assets/glTF/characters/adult_female/alt_outfits/patterns/hexes.png`;
      const pantsPath = `/assets/glTF/characters/adult_female/alt_outfits/patterns/stripes.png`;
      // const beadsPath = `http://localhost:8080/assets/glTF/characters/adult_female/fiona/textures/beads_diff.png`;
      // const earrings = `http://localhost:8080/assets/glTF/characters/adult_female/fiona/textures/earrings_diff.png`;

      // const eyes = `http://localhost:8080/assets/glTF/characters/adult_female/fiona/textures/eyes_diff.png`;
      const hairband = `/assets/glTF/characters/adult_female/fiona/textures/accessories_ao-accessories_rough.png`;
      // const hairPath = `http://localhost:8080/assets/glTF/characters/adult_female/fiona/textures/hair_diff.png`;
      // const headPath = `http://localhost:8080/assets/glTF/characters/adult_female/fiona/textures/head_diff.png`;
      // const labcoat = `http://localhost:8080/assets/glTF/characters/adult_female/fiona/textures/labcoat_diff.png`;
      // const accesories = `http://localhost:8080/assets/glTF/characters/adult_female/fiona/textures/accesories_diff.png`;
      // const body = `http://localhost:8080/assets/glTF/characters/adult_female/fiona/textures/body_diff.png`;

      // this.changeTexture("body", body);
      this.changeTexture("shirt", shirt, true);

      this.changeTexture("jacket", shirt, true);

      this.changeTexture("pants", pantsPath);
      // this.changeTexture("beads", beadsPath);
      // this.changeTexture("earrings", earrings);
      // this.changeTexture("eyes", eyes);
      this.changeTexture("hairtie", hairband, true);
      this.changeTexture("hairband_accessories", hairband, false);
      this.changeTexture("earrings", hairband, true);
      this.changeTexture("bead_necklace", hairband, false);
      this.changeTexture("accessories_earrings", hairband, false);
      this.changeTexture("eyes", hairband, true);

      let outfitPath =
        "http://localhost:8080/assets/glTF/characters/adult_female/alt_outfits/tshirt_jeans.gltf";
      const loader = new GLTFLoader();
      loader.load(outfitPath, (gltf) => {
        const outfit = gltf.scene;
        console.log(outfit); // Logs the structure of the loaded model to the console

        // Set outfit position, rotation, and scale to match the avatar
        outfit.traverse((child) => {
          if (child.isMesh) {
            child.position.set(0, 0, 0); // Adjust if needed
            child.scale.set(1, 1, 1); // Adjust if needed
          }
        });

        // Attach the outfit to the correct node on the avatar
        this.scene.traverse((node) => {
          if (node.name === "torso") {
            // Replace 'torso' with the correct node name
            node.add(outfit);
          }
        });
        // currentOutfit = newOutfit;
      });

      // this.changeTexture("labcoat", labcoat);
      // this.changeTexture("hair", hairPath);
      // this.changeTexture("head", headPath);
      this.startRenderingLoop();
    });

    window.addEventListener("resize", this.onWindowResize, false);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onWindowResize, false);
    this.stopRenderingLoop();
    this.stopAudio();
  },

  methods: {
    gesturesAdd() {
      console.log("GESTURES ADD TEST");
    },
    resumeAudio() {
      try {
        host._features["TextToSpeechFeature"].resumeAudio();
      } catch (e) {
        this.$emit("cant-resume-audio");
      }
    },

    increaseLoadingStep() {
      this.loadingStep++;
      if (this.loadingStep == this.maxLoadingSteps) {
        this.$emit("avatar-loaded");
      }
    },

    speak(text, audioUrl, visemes) {
      host._features["TextToSpeechFeature"].speechMarks = visemes;
      host._features["TextToSpeechFeature"].audioUrl = audioUrl;
      host.TextToSpeechFeature.play(text, null);

      // host.GestureFeature.playGesture("Gesture", "aggressive");
      // host.GestureFeature.playGesture("Gesture", "big");
      // host.GestureFeature.playGesture("Gesture", "defense");
      // host.GestureFeature.playGesture("Gesture", "generic_a"); // good
      host.GestureFeature.playGesture("Gesture", "generic_b"); // good
      // host.GestureFeature.playGesture("Gesture", "generic_c"); // jak jest pytanie to spoko to jest
      // host.GestureFeature.playGesture("Gesture", "heart");
      // host.GestureFeature.playGesture("Gesture", "in"); // ciekawe - kciuki przy sobie
      // host.GestureFeature.playGesture("Gesture", "many"); // rozkłada ręce
      // host.GestureFeature.playGesture("Gesture", "movement"); // ruca ręką do przodu
      // host.GestureFeature.playGesture("Gesture", "one"); // pokazuje palcem na mnie
      // host.GestureFeature.playGesture("Gesture", "self"); // łapie się za cycka
      // host.GestureFeature.playGesture("Gesture", "wave"); // moze na zakonczenie
      // host.GestureFeature.playGesture("Gesture", "you"); // wystawia rączkę dłonią do góry
      // host.AnimationFeature.playAnimation("Emote", "cheer");
      // host.AnimationFeature.playAnimation("Emote", "bored");
      // host.AnimationFeature.playAnimation("Emote", "applause");

      const emotes = host.AnimationFeature.getAnimations("Emote");
      emotes.forEach((emote) => {
        console.log("emote", emote);
      });
    },

    stopAudio() {
      host.TextToSpeechFeature.stop();
    },

    changeTexture(type, texturePath, isVisible) {
      const textureLoader = new THREE.TextureLoader();
      const newTexture = textureLoader.load(texturePath);

      this.scene.traverse((node) => {
        let name = node.name.substring(4);
        console.log(name);
        if (node.isMesh && name === type) {
          node.visible = isVisible;
          const randomColor = Math.floor(Math.random() * 0xffffff);
          node.material.color.setHex(randomColor);
        }
      });

      this.scene.traverse((node) => {
        let name = node.name.substring(4);
        if (node.isMesh && name === type) {
          node.material.map = newTexture;
          node.material.needsUpdate = true;
        }
      });
    },

    initScene() {
      (this.scene = markRaw(new THREE.Scene())),
        (this.clock = new THREE.Clock());
      this.scene.background = new THREE.Color(0x33334d);
      this.scene.fog = new THREE.Fog(0x33334d, 1, 10);
    },

    initRenderer() {
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.adjustRendererToDivSize();

      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.shadowMap.enabled = true;
      this.renderer.setClearColor(0x33334d);
      this.$refs.threejsContainer.appendChild(this.renderer.domElement);
    },

    initCamera() {
      const container = this.$refs.threejsContainer;
      this.camera = new THREE.PerspectiveCamera(
        THREE.MathUtils.radToDeg(0.8),
        container.offsetWidth / container.offsetHeight,
        0.1,
        1000
      );
      this.camera.position.set(-1, 1.5, 1.5);
    },

    initControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.target.set(-1, 1.5, 0);
      this.controls.screenSpacePanning = true;
      this.controls.enabled = false; // disable camera control
    },

    initEnvironmentMap() {
      const loader = new THREE.TextureLoader().setPath("/assets/");
      loader.load("images/machine_shop.jpg", (hdrEquirect) => {
        const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        const hdrCubeRenderTarget =
          pmremGenerator.fromEquirectangular(hdrEquirect);
        hdrEquirect.dispose();
        pmremGenerator.dispose();

        this.scene.environment = hdrCubeRenderTarget.texture;
      });
    },

    initLights() {
      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x000000, 0.6);
      hemiLight.position.set(0, 1, 0);
      hemiLight.intensity = 0.6;
      this.scene.add(hemiLight);

      const dirLight = new THREE.DirectionalLight(0xffffff);
      dirLight.position.set(0, 5, 5);
      dirLight.castShadow = true;
      dirLight.shadow.mapSize.width = 1024;
      dirLight.shadow.mapSize.height = 1024;
      dirLight.shadow.camera.top = 2.5;
      dirLight.shadow.camera.bottom = -2.5;
      dirLight.shadow.camera.left = -2.5;
      dirLight.shadow.camera.right = 2.5;
      dirLight.shadow.camera.near = 0.1;
      dirLight.shadow.camera.far = 40;
      this.scene.add(dirLight);

      const dirLightTarget = new THREE.Object3D();
      dirLight.add(dirLightTarget);
      dirLightTarget.position.set(0, -0.5, -1.0);
      dirLight.target = dirLightTarget;
    },

    initEnvironment(backgroundImage) {
      const groundMat = new THREE.MeshStandardMaterial({
        color: 0x808080,
        depthWrite: false,
      });
      groundMat.metalness = 0;
      groundMat.refractionRatio = 0;
      const ground = new THREE.Mesh(
        new THREE.PlaneGeometry(100, 100),
        groundMat
      );
      ground.rotation.x = -Math.PI / 2;
      ground.receiveShadow = true;
      this.scene.add(ground);

      const backTextureLoader = new THREE.TextureLoader();
      backTextureLoader.load(backgroundImage, (backTexture) => {
        const geo = new THREE.PlaneGeometry(3, 3);
        const mat = new THREE.MeshBasicMaterial({ map: backTexture });
        const plane = new THREE.Mesh(geo, mat);
        plane.position.set(-1, 1.5, -1.5);
        this.scene.add(plane);
      });
    },
    async loadCharacter(scene, characterFile, animationPath, animationFiles) {
      // Asset loader
      // const fileLoader = new THREE.FileLoader();
      const gltfLoader = new GLTFLoader();

      function loadAsset(loader, assetPath, onLoad) {
        return new Promise((resolve) => {
          loader.load(assetPath, async (asset) => {
            if (onLoad[Symbol.toStringTag] === "AsyncFunction") {
              const result = await onLoad(asset);
              resolve(result);
            } else {
              resolve(onLoad(asset));
            }
          });
        });
      }

      // Load character model
      const { character, bindPoseOffset } = await loadAsset(
        gltfLoader,
        characterFile,
        (gltf) => {
          // Transform the character
          const character = gltf.scene;
          scene.add(character);

          // Make the offset pose additive
          const [bindPoseOffset] = gltf.animations;
          if (bindPoseOffset) {
            THREE.AnimationUtils.makeClipAdditive(bindPoseOffset);
          }

          // Cast shadows
          character.traverse((object) => {
            if (object.isMesh) {
              object.castShadow = true;
            }
          });

          return { character, bindPoseOffset };
        }
      );

      // Load animations
      const clips = await Promise.all(
        animationFiles.map((filename /*, index*/) => {
          const filePath = `${animationPath}/${filename}`;

          return loadAsset(gltfLoader, filePath, async (gltf) => {
            return gltf.animations;
          });
        })
      );

      return { character, clips, bindPoseOffset };
    },

    createHost(
      character,
      audioAttachJoint,
      voice,
      engine,
      idleClip,
      faceIdleClip,
      lipsyncClips,
      gestureClips,
      gestureConfig,
      emoteClips,
      blinkClips,
      poiClips,
      poiConfig,
      lookJoint,
      bindPoseOffset,
      clock,
      camera,
      scene
    ) {
      // Add the host to the render loop
      host = new HOST.HostObject({ owner: character, clock });
      renderFn.push(() => {
        host.update();
      });

      // Set up text to speech
      const audioListener = new THREE.AudioListener();
      camera.add(audioListener);
      host.addFeature(HOST.aws.TextToSpeechFeature, false, {
        listener: audioListener,
        attachTo: audioAttachJoint,
        voice,
        engine,
      });
      host.audioListener = audioListener;

      // Set up animation
      host.addFeature(HOST.anim.AnimationFeature);
      this.increaseLoadingStep();
      // Base idle
      host.AnimationFeature.addLayer("Base");
      host.AnimationFeature.addAnimation(
        "Base",
        idleClip.name,
        HOST.anim.AnimationTypes.single,
        { clip: idleClip }
      );
      host.AnimationFeature.playAnimation("Base", idleClip.name);

      // Face idle
      host.AnimationFeature.addLayer("Face", {
        blendMode: HOST.anim.LayerBlendModes.Additive,
      });
      THREE.AnimationUtils.makeClipAdditive(faceIdleClip);
      host.AnimationFeature.addAnimation(
        "Face",
        faceIdleClip.name,
        HOST.anim.AnimationTypes.single,
        {
          clip: THREE.AnimationUtils.subclip(
            faceIdleClip,
            faceIdleClip.name,
            1,
            faceIdleClip.duration * 30,
            30
          ),
        }
      );
      host.AnimationFeature.playAnimation("Face", faceIdleClip.name);
      this.increaseLoadingStep();
      // Blink
      host.AnimationFeature.addLayer("Blink", {
        blendMode: HOST.anim.LayerBlendModes.Additive,
        transitionTime: 0.075,
      });
      blinkClips.forEach((clip) => {
        THREE.AnimationUtils.makeClipAdditive(clip);
      });
      host.AnimationFeature.addAnimation(
        "Blink",
        "blink",
        HOST.anim.AnimationTypes.randomAnimation,
        {
          playInterval: 3,
          subStateOptions: blinkClips.map((clip) => {
            return {
              name: clip.name,
              loopCount: 1,
              clip,
            };
          }),
        }
      );
      host.AnimationFeature.playAnimation("Blink", "blink");
      this.increaseLoadingStep();
      // Talking idle
      host.AnimationFeature.addLayer("Talk", {
        transitionTime: 0.75,
        blendMode: HOST.anim.LayerBlendModes.Additive,
      });
      host.AnimationFeature.setLayerWeight("Talk", 0);
      const talkClip = lipsyncClips.find((c) => c.name === "stand_talk");
      lipsyncClips.splice(lipsyncClips.indexOf(talkClip), 1);
      host.AnimationFeature.addAnimation(
        "Talk",
        talkClip.name,
        HOST.anim.AnimationTypes.single,
        { clip: THREE.AnimationUtils.makeClipAdditive(talkClip) }
      );
      host.AnimationFeature.playAnimation("Talk", talkClip.name);
      this.increaseLoadingStep();
      // Gesture animations
      host.AnimationFeature.addLayer("Gesture", {
        transitionTime: 0.5,
        blendMode: HOST.anim.LayerBlendModes.Additive,
      });
      gestureClips.forEach((clip) => {
        const { name } = clip;
        const config = gestureConfig[name];
        THREE.AnimationUtils.makeClipAdditive(clip);

        if (config !== undefined) {
          config.queueOptions.forEach((option) => {
            // Create a subclip for each range in queueOptions
            option.clip = THREE.AnimationUtils.subclip(
              clip,
              `${name}_${option.name}`,
              option.from,
              option.to,
              30
            );
          });
          host.AnimationFeature.addAnimation(
            "Gesture",
            name,
            HOST.anim.AnimationTypes.queue,
            config
          );
        } else {
          host.AnimationFeature.addAnimation(
            "Gesture",
            name,
            HOST.anim.AnimationTypes.single,
            { clip }
          );
        }
      });
      this.increaseLoadingStep();
      // Emote animations
      host.AnimationFeature.addLayer("Emote", {
        transitionTime: 0.5,
      });

      emoteClips.forEach((clip) => {
        const { name } = clip;
        host.AnimationFeature.addAnimation(
          "Emote",
          name,
          HOST.anim.AnimationTypes.single,
          { clip, loopCount: 1 }
        );
      });

      // Viseme poses
      host.AnimationFeature.addLayer("Viseme", {
        transitionTime: 0.12,
        blendMode: HOST.anim.LayerBlendModes.Additive,
      });
      host.AnimationFeature.setLayerWeight("Viseme", 0);

      // Slice off the reference frame
      const blendStateOptions = lipsyncClips.map((clip) => {
        THREE.AnimationUtils.makeClipAdditive(clip);
        return {
          name: clip.name,
          clip: THREE.AnimationUtils.subclip(clip, clip.name, 1, 2, 30),
          weight: 0,
        };
      });
      host.AnimationFeature.addAnimation(
        "Viseme",
        "visemes",
        HOST.anim.AnimationTypes.freeBlend,
        { blendStateOptions }
      );
      host.AnimationFeature.playAnimation("Viseme", "visemes");
      this.increaseLoadingStep();
      // POI poses
      poiConfig.forEach((config) => {
        host.AnimationFeature.addLayer(config.name, {
          blendMode: HOST.anim.LayerBlendModes.Additive,
        });

        // Find each pose clip and make it additive
        config.blendStateOptions.forEach((clipConfig) => {
          const clip = poiClips.find((clip) => clip.name === clipConfig.clip);
          THREE.AnimationUtils.makeClipAdditive(clip);
          clipConfig.clip = THREE.AnimationUtils.subclip(
            clip,
            clip.name,
            1,
            2,
            30
          );
        });

        host.AnimationFeature.addAnimation(
          config.name,
          config.animation,
          HOST.anim.AnimationTypes.blend2d,
          { ...config }
        );

        host.AnimationFeature.playAnimation(config.name, config.animation);

        // Find and store reference objects
        config.reference = character.getObjectByName(
          config.reference.replace(":", "")
        );
      });

      // Apply bindPoseOffset clip if it exists
      if (bindPoseOffset !== undefined) {
        host.AnimationFeature.addLayer("BindPoseOffset", {
          blendMode: HOST.anim.LayerBlendModes.Additive,
        });
        host.AnimationFeature.addAnimation(
          "BindPoseOffset",
          bindPoseOffset.name,
          HOST.anim.AnimationTypes.single,
          {
            clip: THREE.AnimationUtils.subclip(
              bindPoseOffset,
              bindPoseOffset.name,
              1,
              2,
              30
            ),
          }
        );
        host.AnimationFeature.playAnimation(
          "BindPoseOffset",
          bindPoseOffset.name
        );
      }
      this.increaseLoadingStep();
      // Set up Lipsync
      const visemeOptions = {
        layers: [{ name: "Viseme", animation: "visemes" }],
      };
      const talkingOptions = {
        layers: [
          {
            name: "Talk",
            animation: "stand_talk",
            blendTime: 0.75,
            easingFn: HOST.anim.Easing.Quadratic.InOut,
          },
        ],
      };
      host.addFeature(
        HOST.LipsyncFeature,
        false,
        visemeOptions,
        talkingOptions
      );

      // Set up Gestures
      host.addFeature(HOST.GestureFeature, false, {
        layers: {
          Gesture: { minimumInterval: 3 },
          Emote: {
            blendTime: 0.5,
            easingFn: HOST.anim.Easing.Quadratic.InOut,
          },
        },
      });
      this.increaseLoadingStep();
      // Set up Point of Interest
      host.addFeature(
        HOST.PointOfInterestFeature,
        false,
        {
          target: camera,
          lookTracker: lookJoint,
          scene,
        },
        {
          layers: poiConfig,
        },
        {
          layers: [{ name: "Blink" }],
        }
      );
      this.increaseLoadingStep();
      return host;
    },

    async loadCharacterAndAnimations(params) {
      // Define the glTF assets that will represent the host

      const {
        characterFile,
        animationPath,
        audioAttachJoint,
        lookJoint,
        voice,
      } = params;

      const animationFiles = [
        "stand_idle.glb",
        "lipsync.glb",
        "gesture.glb",
        "emote.glb",
        "face_idle.glb",
        "blink.glb",
        "poi.glb",
      ];
      const gestureConfigFile = "gesture.json";
      const poiConfigFile = "poi.json";

      const voiceEngine = "neural"; // Neural engine is not available for all voices in all regions: https://docs.aws.amazon.com/polly/latest/dg/NTTS-main.html

      const { character, clips, bindPoseOffset } = await this.loadCharacter(
        this.scene,
        characterFile,
        animationPath,
        animationFiles
      );

      this.increaseLoadingStep();

      character.position.set(-1, 0, 0);
      character.rotateY(0.5);

      // Find the joints defined by name
      const audioAttach = character.getObjectByName(audioAttachJoint);
      const lookTracker = character.getObjectByName(lookJoint);

      const gestureConfig = await fetch(
        `${animationPath}/${gestureConfigFile}`
      ).then((response) => response.json());

      // Read the point of interest config file. This file contains options for
      // creating Blend2dStates from look pose clips and initializing look layers
      // on the PointOfInterestFeature.
      const poiConfig = await fetch(`${animationPath}/${poiConfigFile}`).then(
        (response) => response.json()
      );

      const onStopSpeech = () => {
        this.$emit("speaking-stopped");
      };

      const [
        idleClips,
        lipsyncClips,
        gestureClips,
        emoteClips,
        faceClips,
        blinkClips,
        poiClips,
      ] = clips;
      host = this.createHost(
        character,
        audioAttach,
        voice,
        voiceEngine,
        idleClips[0],
        faceClips[0],
        lipsyncClips,
        gestureClips,
        gestureConfig,
        emoteClips,
        blinkClips,
        poiClips,
        poiConfig,
        lookTracker,
        bindPoseOffset,
        this.clock,
        this.camera,
        this.scene
      );

      HOST.aws.TextToSpeechFeature.listenTo(
        HOST.aws.TextToSpeechFeature.EVENTS.stop,
        onStopSpeech
      );
    },

    adjustRendererToDivSize() {
      const container = this.$refs.threejsContainer;
      this.renderer.setSize(container.offsetWidth, container.offsetHeight);
    },

    adjustCameraAspectToDivSize() {
      const container = this.$refs.threejsContainer;
      this.camera.aspect = container.offsetWidth / container.offsetHeight;
    },
    onWindowResize() {
      this.adjustCameraAspectToDivSize();
      this.camera.updateProjectionMatrix();
      this.adjustRendererToDivSize();
    },

    startRenderingLoop() {
      this.rendering = true;
      const render = () => {
        if (!this.rendering) {
          return;
        }
        this.renderID = requestAnimationFrame(render);
        this.controls.update();

        renderFn.forEach((fn) => {
          fn();
        });

        this.renderer.render(this.scene, this.camera);
      };
      render();
    },

    stopRenderingLoop() {
      if (this.renderID) {
        cancelAnimationFrame(this.renderID);
        this.rendering = false;
        this.renderID = null;
      }
    },
  },
};
</script>

<style>
/* Style for the container if needed */
.threejs-container {
  position: relative;
  width: 40vh;
  border-radius: 14px; /* Rounded corners */
  background-color: #e0e0e0; /* Light grey background for visibility */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for better visibility */
  overflow: hidden;
  height: 100%;
}

@media (min-width: 600px) {
  .threejs-container {
    width: 40vh;
    height: 50vh;
  }
}

.threejs {
  width: 100%;
  height: 100%;
}
.avatar-info {
  position: absolute;
  bottom: 12px; /* Position relative to the .threejs-container */
  left: 10px; /* This makes it placed in the top-right corner of the container */
  color: rgba(0, 0, 0, 0.762);

  font-family: "Lato-Regular", sans-serif;
}
.loading-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}
.test-gestures {
}
</style>

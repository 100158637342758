<template>
  <div>
    <h3>{{ $t(subtitle) }}</h3>

    <div class="select-value-container">
      <form @submit.prevent="handleSave">
        <div
          v-for="(option, index) in options"
          :key="index"
          class="select-value"
        >
          <LinguaButton
            :class="{ selected: index === chosenElement }"
            @click="selectValue(index)"
            class="select-button"
          >
            {{ option }}
          </LinguaButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import LinguaButton from "./LinguaButton.vue";
import userService from "@/services/userService";
const emit = defineEmits(["selector-value-chosen"]);

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [String, Number, Object],
    default: null,
  },
  modelColumnName: {
    type: [String, Number, Object],
    default: null,
  },
});

let chosenElement = ref(props.modelValue);

const handleSave = async () => {
  try {
    await userService.setUserData({
      [props.modelColumnName]: chosenElement.value,
    });
    emit("selector-value-chosen");
  } catch (error) {
    alert(error.message);
  }
};

onMounted(async () => {
  if (props.modelValue) {
    chosenElement.value = props.modelValue;
  } else {
    try {
      const userData = await userService.getUserData();
      if (userData[props.modelColumnName]) {
        chosenElement.value = userData[props.modelColumnName];
      }
    } catch (error) {
      console.error(error.message);
    }
  }
});

function selectValue(value) {
  chosenElement.value = value;
}
</script>

<style scoped>
.selected {
  background-color: #4caf50; /* Green background for selected button */
  color: white;
}
.select-value-container {
  width: 100%;
}
.select-value-item {
  display: flex;
  justify-content: center;
  margin: 10px;
}
.select-value-button {
  width: 100px;
}

.value-description {
  display: flex; /* Establishes this element as a flex container */
  flex-direction: column; /* Stacks flex items vertically */
  justify-content: center; /* Centers content vertically */
  align-items: flex-start; /* Centers content horizontally */
  width: 200px;
  padding-left: 10px;
}
</style>

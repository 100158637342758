<template>
  <div>
    <h3>{{ $t("register.chooseInterestsSubtitle") }}</h3>
    <div class="interests-container">
      <div
        v-for="(interest, index) in interests"
        :key="index"
        class="interest-item"
      >
        <LinguaButton
          :variant="'checkbox'"
          :is-checked="interest.isChecked"
          @click="interestClicked(interest, index)"
        >
          {{ level }}
        </LinguaButton>
        <div class="interest-description">{{ $t(interest.name) }}</div>
      </div>

      <LinguaButton @click="nextPressed" v-if="variant == 'onboarding'">
        {{ $t("common.next") }}
      </LinguaButton>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { eventBus } from "../utils/eventBus";
import LinguaButton from "./LinguaButton.vue";
const emit = defineEmits(["next-pressed"]);

import { Interests } from "../constants/interests";

defineProps({
  variant: {
    type: String,
    default: "settings",
  },
});

const interests = ref(
  Interests.map((interest) => ({ name: interest, isChecked: false }))
);

import userService from "@/services/userService";

async function getUserData() {
  return await userService.getUserData();
}
const interestClicked = async (interest) => {
  try {
    interest.isChecked = !interest.isChecked;
    if (interest.isChecked) {
      await userService.addUserInterest({ interest: interest.name });
    } else {
      await userService.removeUserInterest({ interest: interest.name });
    }
  } catch (error) {
    eventBus.addAlert({ message: error.message });
  }
};

function nextPressed() {
  emit("next-pressed");
}

onMounted(async () => {
  try {
    const userData = await getUserData();
    if (userData.interests) {
      interests.value.forEach((interest) => {
        // If the interest name is in the checkedInterests array, set isChecked to true
        interest.isChecked = userData.interests.includes(interest.name);
      });
    }
  } catch (error) {
    console.error(error.message);
  }
});
</script>

<style scoped>
.selected {
  background-color: #4caf50; /* Green background for selected button */
  color: white;
}
.interests-container {
  width: 100%;
}
.interest-item {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.interest-description {
  display: flex; /* Establishes this element as a flex container */
  flex-direction: column; /* Stacks flex items vertically */
  justify-content: center; /* Centers content vertically */
  align-items: flex-start; /* Centers content horizontally */
  width: 200px;
  padding-left: 10px;
}
</style>

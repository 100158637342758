export async function processResponseWithAudio(response) {
  const headers = response.headers;
  const contentLength = headers["text-length"];
  const buffer = await response.data.arrayBuffer();
  const decoder = new TextDecoder("utf-8");
  const responseText = decoder.decode(buffer.slice(0, contentLength));
  const responseJson = JSON.parse(responseText);
  return { responseJson, responseText, buffer, contentLength };
}

export function getAudioUrl(buffer, contentLength) {
  if (contentLength != buffer.byteLength) {
    const audioData = buffer.slice(contentLength, buffer.byteLength);
    const audioBlob = new Blob([audioData], { type: "audio/mpeg" });
    const audioUrl = URL.createObjectURL(audioBlob);
    return audioUrl;
  }
  return null;
}

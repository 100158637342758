<template>
  <div id="app">
    <div class="error-wrapper" v-if="error">
      <div class="error">{{ error }}</div>
    </div>
    <div class="lesson-container">
      <div class="speak-panel" v-if="lesson">
        <div class="left-panel">
          <div class="avatar-box">
            <div class="buttons-container">
              <LinguaButton
                @click="showStopLessonPopup = true"
                :variant="'clean'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
              </LinguaButton>
              <div class="lesson-name">{{ lesson.name }}</div>
            </div>
            <Avatar
              ref="avatarComponent"
              :teacher="teacher"
              :variant="'in-lesson'"
              @speaking-stopped="speakingStopped"
              @avatar-loaded="avatarLoaded"
              @cant-resume-audio="showCantEnableAudioPopup"
            ></Avatar>
          </div>
          <LessonProgress
            v-if="isMobileScreen"
            :steps="lesson.steps"
            :current-step="currentStep.id"
            @move-to-step="moveToStep"
          ></LessonProgress>
        </div>
        <div class="right-panel">
          <div ref="chatContainer" class="chat-container">
            <div
              v-for="message in messages"
              :key="message.id"
              :class="`message ${message.type}`"
            >
              <div v-if="!message.loading" v-html="message.text"></div>
              <div v-else class="spinner"></div>

              <LinguaButton
                @click="repeatResponse(message.timestamp)"
                :variant="'clean'"
                v-if="!message.loading && message.type === 'bot'"
                class="headphones-button"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.47 14.674c-.159-.332-.375-.542-.648-.629a1.047 1.047 0 0 0-.763.025c-.251.103-.44.302-.566.596-.126.289-.093.627.099 1.013l1.96 3.971c.175.36.407.597.697.711.295.12.596.098.902-.065a.91.91 0 0 0 .492-.597c.076-.267.04-.553-.107-.858L8.47 14.675Zm7.052 0-2.058 4.168c-.148.305-.186.59-.115.857a.91.91 0 0 0 .492.597c.306.163.607.185.902.065.295-.114.53-.351.705-.71l1.96-3.972c.192-.386.224-.724.099-1.013-.126-.294-.315-.493-.566-.596a1.07 1.07 0 0 0-.771-.025c-.268.087-.484.297-.648.63ZM3.5 11.554c0 .724.087 1.456.262 2.197.175.741.435 1.468.78 2.182a1.03 1.03 0 0 0-.18.596c-.006.24.051.488.171.744l1.55 3.145c.099.191.235.335.41.434.18.098.367.146.558.146.257 0 .492-.084.705-.253.219-.163.334-.395.345-.694l-1.805-3.677a9.657 9.657 0 0 1-.918-2.999 13.448 13.448 0 0 1-.115-1.814c0-.991.159-1.892.476-2.704a5.97 5.97 0 0 1 1.378-2.092A6.094 6.094 0 0 1 9.248 5.41c.825-.316 1.741-.474 2.747-.474 1.011 0 1.927.158 2.747.474a6.094 6.094 0 0 1 2.132 1.356 5.97 5.97 0 0 1 1.378 2.092c.323.812.484 1.713.484 2.704 0 .649-.041 1.256-.123 1.822a9.902 9.902 0 0 1-.91 2.99l-1.813 3.678c.017.3.131.53.345.694a1.121 1.121 0 0 0 1.263.107.956.956 0 0 0 .41-.434l1.55-3.145c.125-.256.185-.504.18-.744a1.03 1.03 0 0 0-.18-.596c.338-.714.595-1.441.77-2.182.18-.74.271-1.473.271-2.198 0-1.215-.205-2.34-.615-3.374a8.07 8.07 0 0 0-1.73-2.721 7.867 7.867 0 0 0-2.69-1.806C14.426 3.216 13.27 3 11.995 3c-1.268 0-2.424.217-3.468.653a7.886 7.886 0 0 0-2.682 1.806 8.174 8.174 0 0 0-1.738 2.72c-.405 1.036-.607 2.16-.607 3.375Z"
                    fill="currentColor"
                  />
                </svg>
              </LinguaButton>
            </div>
            <div ref="endOfMessages"></div>
          </div>
          <div class="input-container">
            <div class="button-container">
              <ButtonHint message="Click here to talk" :duration="3000" />
              <LinguaButton
                @click="toggleRecording"
                :variant="'clean'"
                :disabled="isRecordingUnavailable"
              >
                <svg
                  v-if="isRecording"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="red"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <!-- Microphone body -->
                  <rect x="9" y="2" width="6" height="12" rx="3"></rect>

                  <!-- Microphone base -->
                  <path d="M5 10v2a7 7 0 0 0 14 0v-2"></path>

                  <!-- Stand -->
                  <line x1="12" y1="22" x2="12" y2="16"></line>

                  <!-- Stand base -->
                  <line x1="8" y1="22" x2="16" y2="22"></line>
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <!-- Microphone body -->
                  <rect x="9" y="2" width="6" height="12" rx="3"></rect>

                  <!-- Microphone base -->
                  <path d="M5 10v2a7 7 0 0 0 14 0v-2"></path>

                  <!-- Stand -->
                  <line x1="12" y1="22" x2="12" y2="16"></line>

                  <!-- Stand base -->
                  <line x1="8" y1="22" x2="16" y2="22"></line>
                </svg>
              </LinguaButton>
            </div>
            <textarea
              ref="autoTextarea"
              class="type-message-box"
              @input="handleUserInput"
              v-model="userInput"
              :rows="1"
              :disabled="isDisabledInput"
              :placeholder="
                isRecording
                  ? $t('lesson.speakOrTypeMessage')
                  : $t('lesson.typeMessage')
              "
            ></textarea>
          </div>

          <LinguaButton @click="sendMessage">{{
            $t("lesson.send")
          }}</LinguaButton>
        </div>
        <LessonProgress
          v-if="!isMobileScreen"
          :steps="lesson.steps"
          :current-step="currentStep.id"
          @move-to-step="moveToStep"
        ></LessonProgress>
      </div>
      <!-- <button
        @click="showDevPanel = !showDevPanel"
        class="devOptionsButton"
        v-if="devOptionsAvailable"
      >
        {{ $t("lesson.devOptions") }}
      </button>
      <div class="import-panel" v-if="showDevPanel">
        <textarea
          class="paste-text"
          v-model="jsonInput"
          :disabled="!isImportEnabled"
          :placeholder="$t('lesson.pasteJSON')"
        ></textarea>
        <LinguaButton @click="importLesson" class="button">Import</LinguaButton>
      </div> -->
    </div>
    <transition name="fade">
      <div>
        <StopLessonPopup
          v-if="showStopLessonPopup"
          @close="closePopup"
          @close-lesson="closeLesson"
          @lesson-stopped="lessonStopped"
        />
      </div>
    </transition>
    <transition name="fade">
      <div>
        <MultiStepRating
          v-if="showRatingPopup"
          :steps="ratingSteps"
          @close="closeRatingPopup"
          @close-lesson="closeLesson"
          :lesson="lesson"
        />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted, nextTick, reactive } from "vue";
import { useStore } from "vuex";
import { processResponseWithAudio, getAudioUrl } from "../utils/audioResponse";

import { RealtimeTranscriber } from "assemblyai";
import { createMicrophone } from "../utils/microphone";
import LinguaButton from "../components/LinguaButton.vue";
import Avatar from "../components/Avatar.vue";
import LessonProgress from "../components/LessonProgress.vue";
import MultiStepRating from "../components/MultiStepRating.vue";
import StopLessonPopup from "../components/StopLessonPopup.vue";
import ButtonHint from "../components/ButtonHint.vue";
import chatService from "@/services/chatService";
import lessonService from "@/services/lessonService";
import router from "@/router/router";
// import teacherService from "@/services/teacherService";

const autoTextarea = ref(null);
const lesson = ref(null);
const teacher = ref(null);
const currentStep = ref(null);
const avatarComponent = ref(null);
const endOfMessages = ref(null);
const isImportEnabled = ref(true);
const nextId = ref(0);
const messages = ref([]);
const isDisabledInput = ref(false);
const userInput = ref("");
const isLessonFinished = ref(false);
const userInputPreviousSentences = ref("");
// const jsonInput = ref("");
const error = ref("");
const isRecording = ref(false);
const showStopLessonPopup = ref(false);
const showRatingPopup = ref(false);
let isRecordingUnavailable = ref(true);
// let devOptionsAvailable = ref(false);
const transcriber = ref(null);
const microphone = ref(null);
// const showDevPanel = ref(false);
const store = useStore();
const isMobileScreen = ref(false);

const transcriberState = ref("stopped");

const ratingSteps = [
  { question: "How satisfied are you with the lesson?" },
  { question: "How do you rate the pace of the lesson?" },
  { question: "How do you rate the lesson topic?" },
  { question: "How do you rate the teacher's attitude?" },
  { question: "Any additional remarks?" },
];

watch(
  messages,
  () => {
    nextTick(() => {
      scrollToBottom();
    });
  },
  { deep: true }
);

const handleUserInput = (event) => {
  transcriberState.value = "stopped";
  const newInput = event.target.value;

  userInputPreviousSentences.value = newInput;
  adjustTextareaHeight();
};

const adjustTextareaHeight = () => {
  nextTick(() => {
    const textarea = autoTextarea.value;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to auto to recalculate
      const computed = window.getComputedStyle(textarea);

      // Calculate the height
      const height =
        textarea.scrollHeight +
        parseFloat(computed.borderTopWidth) +
        parseFloat(computed.borderBottomWidth);

      // Set the new height
      textarea.style.height = `${height}px`;
    }
  });
};

onMounted(async () => {
  checkScreenSize();
  window.addEventListener("resize", checkScreenSize);
  window.addEventListener("keydown", handleKeyDown);

  await getCurrentLesson();
  if (isLastMessageTypeBot()) {
    // say last
  } else {
    sendMessage();
  }
  runAssembly();
});

onUnmounted(() => {
  // Clean up the event listener on component unmount
  stopAssembly();
  window.removeEventListener("resize", checkScreenSize);
  window.removeEventListener("keydown", handleKeyDown);
});

const handleKeyDown = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();
    console.log("sending message handleKeyDown", event);
    sendMessage(event);
  }
};

const checkScreenSize = () => {
  // Adjust the media query as per your mobile breakpoint (here: 768px)
  isMobileScreen.value = window.matchMedia("(max-width: 600px)").matches;
};

const speakingStopped = () => {
  if (isLessonFinished.value) {
    showRatingPopup.value = true;
  }
};

const avatarLoaded = () => {
  console.log("AVATAR LOADED");
};

const showCantEnableAudioPopup = () => {
  console.log("Can't enable audio");
};

const scrollToBottom = () => {
  if (endOfMessages.value) {
    endOfMessages.value.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
};

const toggleRecording = async () => {
  if (!isRecording.value) {
    await startRecording();
    isRecording.value = true;
  } else {
    stopRecording();
    isRecording.value = false;
  }
  autoTextarea.value.focus();
};

const repeatResponse = async (timestamp) => {
  console.log("Repeat message", timestamp);

  try {
    let response = await chatService.repeatResponse(timestamp);
    await processChatResponse(response, null);
  } catch (error) {
    error.value = error;
  }
};

const startRecording = async () => {
  microphone.value = createMicrophone();
  await microphone.value.requestPermission();
  await microphone.value.startRecording((audioData) => {
    transcriber.value.sendAudio(audioData);
  });
};

const stopRecording = () => {
  if (microphone.value) {
    microphone.value.stopRecording();
  }
};

const moveToStep = async (step) => {
  try {
    const botMessage = addMessage("", "bot", null, true);
    const response = await chatService.moveToStep(step);

    await processChatResponse(response, botMessage);

    // return res.data;
  } catch (error) {
    error.value = error;
    isImportEnabled.value = true;
  }
};

const getAssemblyTempKey = async () => {
  try {
    let res = await chatService.getAssemblyTempToken();
    return res.data;
  } catch (error) {
    error.value = error;
    isImportEnabled.value = true;
  }
};

const waitForTranscriberState = async (timeout = 5000) => {
  return new Promise((resolve) => {
    const startTime = Date.now();

    const checkState = () => {
      if (
        transcriberState.value === "corrected" ||
        transcriberState.value === "error"
      ) {
        resolve();
      } else if (Date.now() - startTime >= timeout) {
        resolve(); // Just resolve if the timeout expires
      } else {
        setTimeout(checkState, 100); // Check every 100ms
      }
    };
    checkState();
  });
};

const stopAssembly = async () => {
  if (transcriber.value) {
    console.log("Unmounting and stopping transcriber.");
    stopRecording();
    transcriber.value.close(); // Or transcriber.value.disconnect() based on the API
  }
};

const runAssembly = async () => {
  try {
    const assemblyTempKey = await getAssemblyTempKey();
    transcriber.value = new RealtimeTranscriber({
      token: assemblyTempKey.token,
    });

    transcriber.value.on("open", ({ sessionId }) => {
      console.log(`Session opened with ID: ${sessionId}`);
      isRecordingUnavailable.value = false;
      transcriberState.value = "open";
    });
    transcriber.value.on("error", (error) => {
      console.error("Error:", error);
      isRecordingUnavailable.value = true;
      transcriberState.value = "error";
    });
    transcriber.value.on("close", (code, reason) => {
      console.log("Session closed:", code, reason);
      isRecordingUnavailable.value = true;
      transcriberState.value = "stopped";
    });
    transcriber.value.on("transcript", async (transcript) => {
      if (!transcript.text) {
        return;
      }
      if (transcript.message_type === "PartialTranscript") {
        console.log("Partial:", transcript.text);
        userInput.value = userInputPreviousSentences.value + transcript.text;
        transcriberState.value = "partial";
      } else {
        console.log("Final:", transcript.text);
        let text = transcript.text;
        let fullText = userInputPreviousSentences.value + text + " ";
        userInputPreviousSentences.value = fullText;
        userInput.value = userInputPreviousSentences.value;
        transcriberState.value = "final";
        try {
          let res = await chatService.transcribeInput(fullText);
          fullText = res.data.text;
          userInputPreviousSentences.value = fullText;
          userInput.value = userInputPreviousSentences.value;
          transcriberState.value = "corrected";
        } catch (e) {
          console.log("ERROR with transcription", e);
          transcriberState.value = "error";
        }
      }
      adjustTextareaHeight();
    });
    await transcriber.value.connect();
  } catch (error) {
    console.error(error);
  }
};

const addHistoryMessages = (historyMessages) => {
  historyMessages.forEach((message) => {
    addMessage(
      message.message,
      determineMessageType(message.person),
      message.timestamp
    );
  });
};

const determineMessageType = (person) => {
  switch (person) {
    case "T":
      return "bot";
    case "S":
      return "user";
    default:
      return "moderator";
  }
};

const addMessage = (text, type, timestamp, loading = false) => {
  let message = {
    id: nextId.value++,
    text: text,
    type,
    timestamp,
    loading,
  };
  messages.value.push(reactive(message));
  return message;
};

const isLastMessageTypeBot = () => {
  if (messages.value.length === 0) return false;
  const lastMessage = messages.value[messages.value.length - 1];
  return lastMessage.type === "bot";
};

// const applyLessonImportToChatWindow = (lessonJSON, stopMarker) => {
//   if (lessonJSON && lessonJSON.history && Array.isArray(lessonJSON.history)) {
//     for (const item of lessonJSON.history) {
//       if (item.message === stopMarker) {
//         return;
//       }
//       let type = determineMessageType(item.person);
//       addMessage(item.message, type, false);
//     }
//   }
// };

const getCurrentLesson = async () => {
  // error.value = "";
  try {
    const response = await lessonService.getCurrentLesson();
    const data = await response.data;

    if (data.success) {
      addHistoryMessages(data.data.chatHistory);
      lesson.value = data.data.lesson;
      teacher.value = data.data.teacher;
      currentStep.value = data.data.currentStep;
    } else {
      console.error(`Error ${data.error.code}: ${data.error.message}`);
    }
  } catch (error) {
    // Handle network or other errors
    console.error("An error occurred:", error);
  }
};

// const importLesson = async () => {
//   try {
//     error.value = "";
//     isImportEnabled.value = false;
//     const jsonData = JSON.parse(jsonInput.value);
//     await axios.post(
//       `${process.env.VUE_APP_SERVER_URL}/lesson/import`,
//       jsonData
//     );
//     isImportEnabled.value = true;
//     messages.value = [];
//     applyLessonImportToChatWindow(jsonData, "STOP");
//   } catch (error) {
//     error.value = error;
//     isImportEnabled.value = true;
//   }
// };

// const resetChat = async () => {
//   try {
//     error.value = "";
//     await axios.post(`${process.env.VUE_APP_SERVER_URL}/lesson/reset`);
//     messages.value = [];
//   } catch (error) {
//     error.value = error;
//   }
// };

const processChatResponse = async (response, botMessage) => {
  const { responseJson, responseText, buffer, contentLength } =
    await processResponseWithAudio(response);

  if (botMessage) {
    botMessage.text = responseJson.chatResponse;
    botMessage.loading = false;
    botMessage.timestamp = responseJson.timestamp;
  }
  currentStep.value = responseJson.currentStep;
  userInput.value = "";
  userInputPreviousSentences.value = "";

  await nextTick();
  scrollToBottom();
  adjustTextareaHeight();
  const audioUrl = getAudioUrl(buffer, contentLength);
  if (audioUrl) {
    avatarComponent.value.speak(responseText, audioUrl, responseJson.visemes);
  }
  if (responseJson.isLessonFinished) {
    isLessonFinished.value = true;
  }
};

const sendMessage = async (event) => {
  try {
    // console.log("SENDING message", event);
    error.value = "";
    if (userInput.value || !event) {
      isDisabledInput.value = true;
      if (event && transcriberState.value !== "stopped") {
        // if no event that's just first message - start
        await waitForTranscriberState();
      }
      const userInputText = userInput.value.trim() || "";
      if (event) {
        addMessage(userInputText, "user");
      }
      const botMessage = addMessage("", "bot", null, true);

      try {
        const response = await chatService.sendMessage(
          userInputText,
          store.state.speakerOn
        );

        await processChatResponse(response, botMessage);
      } catch (error) {
        console.log("ERROR", error);
        botMessage.loading = false;
      }
    }
  } catch (error) {
    error.value = error;
    console.error("Error sending request:", error);
  }

  if (!isLessonFinished.value) {
    isDisabledInput.value = false;
  }
};

const closePopup = async () => {
  showStopLessonPopup.value = false;
};

const closeRatingPopup = async () => {
  showRatingPopup.value = false;
};

const closeLesson = async () => {
  router.push("/lessons");
};

const lessonStopped = async () => {
  showRatingPopup.value = true;
  avatarComponent.value.stopAudio();
};
</script>

<style lang="scss">
@import "../styles/variables.scss";

/* Add basic styling */
.chat-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: auto;
  height: calc(80vh - 140px);
  padding: 5px;
}

.message {
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 14px;
  padding-right: 10px;
  margin-bottom: 10px;
  border-radius: 12px;
  color: white;
  max-width: 80%;
  margin: 5px;
  text-align: left;
}

.message p {
  all: unset; /* Resets all inherited or user-agent (browser default) styles */
  display: block; /* Reapply block display to preserve paragraph behavior */
  margin: 0; /* Reset margin */
  padding: 0; /* Reset padding */
}

.user {
  background-color: #f8f8f8;
  color: #1d1a31;
  align-self: flex-end;
  margin-left: auto;
}

.bot {
  background-color: #9a4c95;
  align-self: flex-start;
  margin-right: auto;
  padding-bottom: 6px;
}
.moderator {
  background-color: aqua;
  color: black;
  align-self: flex-start;
  margin-right: auto;
}

input {
  padding: 10px;
  margin: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 1px;
}

.spinner {
  border: 1px solid rgba(256, 256, 256, 0.3);
  border-radius: 50%;
  border-top: 1px solid white;
  width: 14px;
  height: 14px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin-left: 4px;
  margin-right: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.left-panel {
  display: flex;
  height: 34vh;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media (min-width: 600px) {
  .left-panel {
    height: auto;
  }
}

.paste-text {
  height: 200px;
  width: 100%;
}
.right-panel {
  display: flex;
  flex-direction: column;
  height: 57vh;
  margin-right: 10px;
  margin-top: 10px;
  width: 100%;
}

@media (min-width: 600px) {
  .right-panel {
    height: auto;
  }
}

.lesson-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centers items horizontally */
  align-items: center; /* Optional: centers items vertically */
  width: 100%;
  height: 100%;
}
.speak-panel {
  justify-content: center;
  width: 100%;
}

@media (min-width: 600px) {
  .speak-panel {
    display: flex;
  }
}

.import-panel {
  width: 100%;
}

.error {
  color: white;
  max-width: 500px;
  background-color: lightcoral;
  padding-right: 50px;
  padding-left: 50px;
}
.error-wrapper {
  margin: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.devOptionsButton {
  border: #0b93f6;
  background-color: aliceblue;
}
.devOptionsButton:hover {
  border: #004b81;
  background-color: rgb(136, 162, 185);
}

.input-container {
  padding-left: 10px;
  display: flex;
  justify-content: left; /* Horizontally center the button */
  align-items: center; /* Vertically center the button */
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: -15px;
  color: $color-base-darker;
}

.speaker-icon {
  width: 24px;
  height: 24px;
  fill: #333;
}

.type-message-box {
  font-family: "Lato", sans-serif;
  padding: 8px;
  font-size: 16px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid gray;
  line-height: 1.5; /* Adjust line height as needed */
  max-height: 200px; /* Set a maximum height if needed */
  resize: none; /* Disable manual resizing */
  overflow-y: hidden; /* Hide scrollbar until necessary */
  box-sizing: border-box; /* Include padding and border in the height calculation */
  margin-left: 5px;
}

.type-message-box:focus {
  outline: 1px solid $color-base-alfa; /* Custom outline style */
  border: 1px solid $color-base-lighter; /* Custom outline style */
}

.lesson-name {
  font-size: 16px;
  width: 100%;
  font-family: "Lato-Regular", sans-serif;
  color: #333;
}

@media (min-width: 600px) {
  .lesson-name {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.avatar-box {
  height: 30vh;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.headphones-button {
  margin-left: auto;
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  width: 20px;
  height: 20px;
}
</style>
